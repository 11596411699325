.documents{
    width: 100%;
    min-height: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: auto;
}
.documents-page-content{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    height: auto;
    box-sizing: border-box;
    align-items: center;
    max-width: 100%;
}
.first-content-block,.second-content-block{
    width: 66%;
    max-width: 35%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    justify-content: center;
    margin: 0px 0px 20px 0px;
}
.first-content-block img,.second-content-block img{
    width: auto;
    height: auto;
    max-width: 60%;
    margin: 20px 10px;
    box-sizing: border-box;
    box-shadow: -7px 7px 10px rgba(0, 0, 0, 0.1);
}
.first-content-block img:hover,.second-content-block img:hover{
    transform: scale(1.05);
    transition: 0.3s;
}
.first-content-block img:not(hover),.second-content-block img:not(hover){
    transform: scale(1);
    transition: 0.3s;
}
@media screen and (max-width:500px) {
    .news-page-title{
        font-size: 20px;
    }
    .first-content-block, .second-content-block{
        max-width: 100%;
        width: 100%;
    }
}