.user-page-part{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.user-page-content{
    width: 100%;
    height: 91%;
    display: flex;
    overflow-y: scroll;
    justify-content: flex-end;
}
.admin-page-part{
    background-image: url('../../images/top-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-width: 100%;
    height: auto;
}
.loading-div{
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar-menu-btn{
    display: flex;
    width: auto;
    height: auto;
    left: 20px;
    top:13px;
    color:#FFFFFF;
    position: absolute;
    justify-content: center;
}
.sidebar-menu-btn a{
    width: 100%;
    height: 100%;
    display: flex;
    padding: 5px;
    justify-content: center;
    border: 1px solid #1f93d1;
    align-items: center;
    border-radius: 10px;
    background-color: #4DAFE4;
}
.sidebar-menu-btn a:hover{
    transform: scale(1.07);
    transition: 0.2s;
}
.sidebar-menu-btn a:not(hover){
    transform: scale(1);
    transition: 0.2s;
}
.sidebar-menu-btn a:active{
    transform: scale(0.97);
}

.admin-page-content{
    max-width: 100%;
    height:92%;
    overflow-y: scroll;
    display: flex;
    justify-content: flex-end;
}
.sidebar{
    width: 20%;
    height: 100%;
    z-index: 2;
    left: 0;
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.sidebar-items{
    width: 85%;
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px 20px 10px;
    background-color:#4DAFE4;
    border-radius: 15px;
}
.sidebar-logo{
    width: 95%;
    height: 25%;
    display: flex;
    justify-content: center;

}
.sidebar-logo img{
    width: auto;
    max-width: 100%;
    max-height: 120%;
    height: auto;
}
.sidebar-data{
    padding-top: 10px;
    width: 100%;
    height: 80%;
}
.sidebar-list{
    width: 100%;
    height: 100%;
    padding: 0px;
    padding-top: 20px;
}
.sidebar-list li{
    width: 100%;
    height: 10%;
    list-style-type: none;
}
.sidebar-list-icon{
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebat-list-title{
    width: 80%;
    height: 100%;
    display: flex;
    padding-left: 20px;
    align-items: center;
}
.sidebar-menu{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}
.sidebar-list-row a{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 1.2rem;
    padding-left: 10px;
    border-radius: 10px;
}
.sidebar-list-row a:hover{
    padding: 0;
    /* transform: scale(1.02); */
    background-color:#1f93d1;
    transition: 0.2s;
}
.sidebar-list-row a:not(hover){
    transform: scale(1);
    transition: 0.2s;
}
.sidebar-list-row a:active{
    transform: scale(0.97);
}
.exit-icon:hover{
    transform: scale(1.07);
    transition: 0.2s;
}
.exit-icon:not(hover){
    transform: scale(1);
    transition: 0.2s;
}

@media screen and (max-width:1100px){
    .sidebar{
        width: 290px;
        left: -100%;
        transition: 0.5s;
    }
    .sidebar-menu-btn{
        left: 10px;
    }
    .sidebar-items{
        width: 89%;
        height: 93%;
    }
}
@media screen and (max-width:600px){
    .sidebar-list li{
        height: 13%;
    }
}
