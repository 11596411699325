.regstration-page{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../images/top-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.registration-form{
    background-color: #5DADD9;
    border-radius: 20px;
    color: #eb3f3f;
    min-width: 40%;
    height: auto;
    width: auto;
}
.registration-form-title{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 15px 0px;
    font-weight: 500;
    font-size: 2.2rem;
    color: #fff;
}
.registration-form-data{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.reg-form-input{
    width: 100%;
    height: auto;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reg-form-input input{
    width: 85%;
    height: auto;
    padding: 7px 10px;
    box-sizing: border-box;
    border: 0;
    background-color: transparent;
    border-bottom: 3px solid #fff;
    color: #fff;
    font-size: 1.1rem;
    outline:none;
}
::placeholder { /* Most modern browsers support this now. */
    color:    rgb(236, 236, 236);
}
.registration-form-policy{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}
.form-policy{
    width: 85%;
    height: auto;
    color: #fff;
    font-size: 0.9rem;
    line-height: 22px;
}
.form-policy input{
    width: auto;
    height: auto;
    margin-right: 10px;
    box-sizing: border-box;
}
.registration-form-button{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 25px 0px;
    box-sizing: border-box;
}
.registration-form-button button{
    width: auto;
    height: auto;
    padding: 7px 30px;
    margin: 0% 5%;
    outline: none;
    font-size: 1.2rem;
    border:0;
    border-radius: 10px;
    color: #fff;
    background-color: #0085CE;
}

.fail-page{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: url('../images/top-bg.jpg');
    color: #000;
    font-size: 20px;
    text-align: center;
}

.success-page{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../images/top-bg.jpg');
}
.fail-title{
    font-size: 2rem;
    font-weight: 600;
    width: auto;
    height: auto;
}
.fail-text{
    width: auto;
    height: auto;
    font-size: 1.5rem;
}
.fail-button{
    width: auto;
    height: auto;
    padding-top: 30px;
}
.fail-button button{
    border: 0;
    background-color: #0085CE;
    border-radius: 10px;
    padding: 10px 15px;
    color: #fff;
    font-size: 1.2rem;
}
#btn:disabled{
    background-color: gainsboro;
    color: rgb(161, 160, 160);
}