.admin-protocol{
    display: grid;
    width: 80%;
    box-sizing: border-box;
    padding-right: 10px;
    min-height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    row-gap: 20px;
    column-gap: 20px;
    height: auto;
}
.admin-protocol-table{
    grid-column: 1/6;
    grid-row: 2/10;
    background-color: #4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    border: 1px solid #0085CE;
    justify-content: space-between;
}
.admin-protocol-table-title{
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.admin-protocol-table-title-text{
    width: auto;
    height: 60%;
    padding: 0px 15px;
    border-radius: 10px;
    color: #FFF;
    display: flex;
    font-size: 1.2rem;
    background-color: #0085CE;
    justify-content: center;
    align-items: center;
}
.users-list{
    width: 98%;
    height: 88%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.users-list{
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
}
.admin-protocol-table-data{
    height: 87%;
    width: 100%;
    overflow: auto;
}
.admin-protocol-ul{
    width: 100%;
    height: 100%;
    padding: 0;
}

.admin-protocol-li{
    width: auto;
    height: auto;
    min-height: 10%;
    display: flex;
    list-style-type: none;
    justify-content: center;
}

.admin-protocol-li-div:hover{
    background-color: #0085CE;
}
.admin-protocol-li-div:active{
    transform: scale(0.98);
    transition: 0.1s;
}
.admin-protocol-li input{
    width: 10%;
    height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-protocol-li-div{
    width: 85%;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 3px solid #FFF;
    box-sizing: border-box;
    padding: 2px 10px;
    border-radius: 10px 10px 0px 0px;

}
.admin-protocol-li-div label{
    font-size: 1.2rem;
}


.admin-protocol-period{
    grid-column: 7/11;
    grid-row: 2/6;
    background-color: #4DAFE4;
    border-radius: 10px;
}
.admin-protocol-sender{
    grid-column: 7/11;
    grid-row: 6/10;
    background-color: #4DAFE4;
    border-radius: 10px;
}
.ad-protocol-select{
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ad-protocol-select input, #ad-select, option {
    width: 93%;
    height: 40px;
    padding: 0px 10px;
    border-style: none;
    text-align: center;
    border-radius: 10px;
    background-color: #9CDCFF;
    outline: none
}

.admin-protocol-date{
    height: 33%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.admin-protocol-date input{
    width: 40%;
    height: 40px;
    padding: 0px 10px;
    border-style: none;
    border-radius: 10px;
    background-color: #9CDCFF;
    outline: none;
}
.admin-protocol-date-input{
    width: 40%;
    height: 50%;
}
.admin-protocol-button{
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-protocol-button button{
    width: auto;
    max-width: 85%;
    height: 50%;
    background-color: #0085CE;
    color: #FFF;
    font-size: 1.2rem;
    border-radius: 10px;
    border-style: none;
    box-sizing: border-box;
}

.admin-protocol-sender-title,.admin-protocol-sender-browse{
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 1.2rem;
}
.admin-protocol-sender-browse label{
    width: auto;
    max-width: 80%;
    height: 80%;
    text-align: center;
    background-color: #0085CE;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin-protocol-li label{
    height: 100%;
}
.admin-protocol-sender-title-text{
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}