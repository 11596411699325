.admin-time{
    display: grid;
    width: 80%;
    box-sizing: border-box;
    padding-right: 10px;
    min-height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    row-gap: 20px;
    column-gap: 20px;
    height: auto;
}
.admin-time>.admin-stat-table{
    grid-column: 1/5;
    grid-row: 1/9;
    background-color: #4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    border: 1px solid #0085CE;
}
.admin-time>.ad-stat-table{
    height: auto;
}
.stat-tr-th,.stat-tr-td{
    width: auto;
    height: 45px;
}
.admin-time .admin-stat-table-data{
height: 60%;
}
.admin-time .admin-stat-table-date input{
    height: 35px;
}
.admin-time .admin-stat-diagram-data{
    width: 90%;
}