*{
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
}
body{overflow-y: overlay;}
body::-webkit-scrollbar {
    width: 10px;
}
body::-webkit-scrollbar-thumb {
    background-color: #A3DDF3;    /* цвет плашки */
    border-radius: 20px;       /* закругления плашки */
}
.main-top{/*ВЕРХУШКА С КАРТИНКОЙ*/
    background-image: url('../images/top-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.main-header{/*ЧАСТЬ ПОД ПЕРЕВОД, КНОПКУ И НАВИГАЦИЮ*/
    width:100%;
    height: 20%;
    margin: 0;
}
button:disabled{
    background-color:#e9e9e9;
    border: 1px solid #e9e9e9;
}
.main-header-head{/*часть под перевод и кнопку*/
    width: 100%;
    height: 35%;
    display: flex;
    justify-content: space-between;
}
.main-header-language{/*перевод*/
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lang-img{/*картинка флага*/
    width: auto;
    height: 30px;
}
.moscow-news{
    width: auto;
    height: auto;
}
.moscow-news *{
    width: auto;
    height: auto;
}
.b-widget_addition{
    width: auto;
    height: auto;
}
.main-header-auth{/*часть под кнопку*/
    height: 100%;
    width: 42%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.back-to-top {
    position: fixed;
    bottom: 50vh;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: #A3DDF3;
    color: white;
    cursor: pointer;
    border-radius: 100px;
    display: flex;
}
.back-to-top a{
    width: 100%;
    height: 100%;
}

.auth-button{/*КНОПКА АВТОРИЗАЦИИ И ЕЁ АНИМАЦИЯ*/
    width: 150px;
    height: 35px;
    background-color: #0085CE;
    color: #FFFFFF;
    border-style: none;
    border-radius: 5px;
    font-weight: 300;
    font-size: 1.1rem;
    padding: 0;
    margin-left: 20px;

}
.auth-button button{/*КНОПКА АВТОРИЗАЦИИ И ЕЁ АНИМАЦИЯ*/
    margin:0;
}
@keyframes hov {
    100% {
        transform: scale(1.15);
    }
}
button:hover{
    transform: scale(1.05);
    transition: 0.2s;
}
button:not(hover){
    transform: scale(1);
    transition: 0.2s;
}
button:active{
    transform: scale(0.97);
}


.main-header-nav{/*оставшая чатсь header'а*/
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: center;
}
.main-header-link{
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding-top: 90px;
    z-index: 2;
}
#testmain .main-header-link{
    z-index: 0;
}
.link{
    display: flex;
    width: auto;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-decoration:none;
    color: #005099;
    font-weight: 300;
}
.main-part{
    width: 100%;
    height: 80%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(10, 1fr);
    row-gap:20px
}
.main-logo{
    grid-column:5/9;
    grid-row: 1/4;
    display: flex;
    justify-content: center;
}
.imag{
    margin-top: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
}
.imag img{
    width: auto;
    height: auto;
    max-height: 100%;
}
.main-title{
    grid-column:3/11;
    grid-row: 4/5;
    align-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5DADD9;
    font-weight: 350;
    font-size: 2.8rem;
}
.title-text{
    width: auto;
    height: auto;
}
.main-text{
    grid-column:5/9;
    text-align: center;
    grid-row: 5/7;
    color: #6C757D;
    font-weight: 300;
    font-size: 1.3rem;
    line-height: 1.7rem;
}
.itstars{
    grid-column:3/5;
    grid-row: 4/9;
    display: flex;
    justify-content: flex-end;
}
.itst{
    width: 100%;
    height: auto;
}
.mercury{
    grid-column:9/11;
    grid-row: 4/9;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-end;
}
.test-block{
    grid-column: 5/9;
    grid-row: 7/9;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.test-block-title{
    width: 100%;
    height: 30%;
    display: flex;
    color:#4facdd;
    font-size: 2rem;
    font-weight: 300;
    justify-content: center;
    align-items: center;
}
.test-block-btn{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 70%;
}
.test-page-button{
    width: 35%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.test-block-btn button{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color:#4facdd;
    border-style: none;
    color: #FFFFFF;
    font-size: 1.3rem;
}

.edit-div{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #3b3b3b;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.edit-header{
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
}
.edit-header-btn{
    height: 100%;
    width: 50%;
    display: flex;
}
.edit-image{
    max-height: 100%;
    height: 85%;
    width: auto;
    max-width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
}
.edit-image img{
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
}
.edit-header-btn a{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.next-prev{
    display: flex;
    justify-content: space-evenly;
    width: 25%;
}
.next-prev a{
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit-param{
    width: 40%;
    height: 7%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.reset-btn-div{
    width: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reset-btn-div a{
    display: flex;
    justify-content: center;
    align-items:center;
}
#br, #con{
    width: 70%;
}
.css-1qw96cp{
    box-sizing: border-box;
}
.range{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
}
.range label{
    color: #FFFFFF;
    display: flex;
    justify-content: center;
}

.merc{
    width: 80%;
    height: auto;
    display: flex;
    justify-content: flex-end;
}
.merc img{
    width: 240px;
    height: auto;
}
.itst img{
    width: 240px;
    height: auto;
}

.version{
    grid-column: 2/13;
    grid-row: 9/11;
    color: #808080;
    display: flex;
    line-height: 1.7rem;
    align-items: flex-end;
    font-weight: 300;
}





.main-news{
    height: 60%;
    background-image: url('../images/news.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    grid-template-columns: repeat(14,1fr);
    grid-template-rows: repeat(12,1fr);
}
.main-news1{
    height: 75%;
    background-image: url('../images/news.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.react-multiple-carousel__arrow--left{
    left: calc(1% + 1px) !important;
}
.react-multiple-carousel__arrow--right{
    right: calc(1% + 1px) !important;
}

.css-r40f8v-MuiTypography-root,.css-46bh2p-MuiCardContent-root,.css-1t6e9jv-MuiCardActions-root{
    height: auto;
}
.css-1t6e9jv-MuiCardActions-root{
    width: auto;
    padding: 15px;
}
.css-1t6e9jv-MuiCardActions-root{
    padding: 20px;
    box-sizing: border-box;
}
.css-b6lsxj-MuiButtonBase-root-MuiCardActionArea-root{
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 7px;
}
.card-date{
    width: auto;
    height: auto;
    display: flex;
    padding: 5px 7px;
    font-size: 0.8rem;
}
.card-type{
    width: auto;
    height: auto;
    display: flex;
    padding: 5px 7px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;

}
.css-34maq8-MuiPaper-root-MuiCard-root{
    box-shadow:20px 20px 100px -60px #5a5a5a;
}
.news-title1{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.react-multi-carousel-list{
    height: 90%;
    width: 100%;
}
.carousel-div{
    width: 50%;
    height: 100%;
    background-color: #005099;
}
.news-card-block{
    width: 30%;
    height: auto;
    background-color: #005099;
    border-radius: 10px;
}
.react-multiple-carousel__arrow{
    background:rgb(163 221 243)!important
}
.react-multiple-carousel__arrow:hover{
    background: #5dadd9 !important;
}
.css-46bh2p-MuiCardContent-root{
    padding: 10px 0px 10px 10px;
    box-sizing: border-box;
}
.react-multi-carousel-list li{
    padding: 0px 10px;
}
.news-title1 a{
    width: auto;
    height: auto;
    font-weight: 300;
    font-size: 2rem;
    color: #FFFFFF;
    text-decoration: none;
}
.react-multiple-carousel__arrow--right,.react-multi-carousel-track,.react-multiple-carousel__arrow--left{
    width: auto;
    height: auto;
}
.news-content{
    grid-column: 3/13;
    grid-row: 1/13;
    display: grid;
    grid-template-columns: repeat(14,1fr);
    grid-template-rows: repeat(10,1fr);
    column-gap: 20px;
}
.news-title{
    grid-column: 1/15;
    grid-row: 1/3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #FFFFFF;
    font-weight: 300;
}
.news-title a{
    font-size: 2rem;
    width: auto;
    height: auto;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 300;
}
.all-news{
    grid-column: 1/15;
    grid-row: 10/11;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #FFFFFF;
    font-weight: 200;
}
.all-news a{
    width: auto;
}






.main-test{
    height: 70%;
    background-image: url('../images/test.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-template-rows: repeat(9, 1fr);
    column-gap:10px
}
.test-content{
    grid-column: 3/13;
    grid-row: 1/9;
    display: grid;
    grid-template-columns: repeat(10, 1fr);   
    grid-template-rows: repeat(10, 1fr);
    column-gap:15px;
}
.test-title{
    width: 100%;
    font-weight: 300;
    grid-column: 2/10;
    grid-row: 1/3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #4facdd;
}
.test-title-text{
    width: auto;
    height: auto;
    font-size: 2.5rem;
}

.main-partner{
    height: 70%;
    background-image: url('../images/partner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
}
.partner-content{
    width: 80%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(8, 1fr);
    row-gap: 20px;
    column-gap: 20px;
}
.partner-title{
    grid-column: 1/14;
    grid-column: 1/2;
    font-weight: 300;
    grid-column: 1/14;
    grid-row: 1/3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #FFFFFF;
}
.css-mhc70k-MuiGrid-root{
    height: auto;
}
.partition{
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: center;

}
.partition img{
    height: 100%;
    width: auto;
    display: flex;
}


.main-prize{
    height: 80%;
    background-image: url('../images/prize.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    font-size: 1.2rem;
    grid-template-columns: repeat(14, 1fr);
    grid-template-rows: repeat(10, 1fr);
}
.prize-content{
    grid-column: 4/12;
    grid-row: 1/13;
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-template-rows: repeat(11, 1fr);
    row-gap: 20px;
}
.prize-title{
    grid-column: 1/15;
    grid-row: 1/3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 300;
    color: #4facdd;
}

.main-contact{
    max-height: 100%;
    height: 70%;
    background-image: url('../images/footer.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    color: #FFFFFF;
    grid-template-columns: repeat(14, 1fr);
    grid-template-rows: repeat(14, 1fr);
}
.contact-content{
    grid-column: 3/13;
    grid-row: 1/15;
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-template-rows: repeat(16,1fr);
    column-gap: 10px;
    row-gap: 20px;
}
.contact-content-title{
    grid-column: 1/15;
    grid-row: 1/5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 300;
    color: #FFFFFF;
}
.ftizis-info{
    grid-column: 3/7;
    grid-row: 12/13;
    display: flex;
    justify-content: center;
    align-items: center;
}
.social{
    grid-column: 7/9;
    grid-row: 12/13;
    display: flex;
    justify-content: center;
    align-items: center;
}
.social a{width: auto;}
.vector-info{
    grid-column: 9/13;
    grid-row: 12/13;
    display: flex;
    justify-content: center;
    align-items: center;
}
.soc-img{
    width: 50px;
    padding-left: 20px;
    height: 50px;
}

.user-page{
    background-image: url('../images/top-bg.jpg');
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.admin-page{
    background-image: url('../images/top-bg.jpg');
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.rusprofile{
    grid-row: 13/14;
    grid-column: 1/15;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.rusprofile1{
    grid-row: 14/15;
    grid-column: 1/15;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.rusprofile a{
    width: auto;
    height: auto;
    color: #FFFFFF;
    margin-left: 10px ;
}













































/*АДАПТАЦИЯ*/
@media screen and (max-width: 1450px){
    .test-content{
        grid-column: 2/14;
    }
}
@media screen and (max-width: 1300px){
    .itstars{
        grid-column:2/4;
        grid-row: 4/9;
        display: flex;
        justify-content: flex-end;
    } 
    .mercury{
        display: flex;
        justify-content: flex-start;
        grid-column: 10/12;
        grid-row: 4/9;
    } 
}
@media screen and (max-width: 1200px){
    .main.text{
        grid-row: 5/7;
        font-size: 0.8 rem;
    }
    .main-partner{
        height: 80%;
    }
    .test-block{
        grid-column: 4/10;
    }
    .partner-content{
        grid-column: 2/14;
        grid-row: 1/10;
        display: grid;
        grid-template-columns: repeat(13, 1fr);   
        grid-template-rows: repeat(14, 1fr);
        column-gap:15px;
        row-gap: 10px;
    }
    .news-content{
        grid-column: 2/14;
    }
    .main-title{
        font-size: 2.2rem;
    }
    .main-logo{
        grid-column: 5/9;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-row: 1/4;
    }
    .imag{
        height: 80%;
    }
    .mercury img{
        width: 180px;
        height: auto;
    }
    .itst img{
        width: 180px;
        height: auto;
    }
}
@media screen and (max-width: 1000px){
    .mercury{
        grid-column: 10/12;
    }
    .mercury img{
        width: 150px;
        height: auto;
    }
    .itst img{
        width: 150px;
        height: auto;
    }
    .edit-header-btn, .edit-param{
        width: 75%;
    }
    .itstars{
        grid-column: 2/4;
    }
    .main-text{
        grid-column: 4/10;
    }
    .contact-content{grid-column: 2/14;}
    .news-content{grid-column: 1/15;}
}
@media screen and (max-width: 800px){
    .main-test{height: 80%;}
    .test-content{
        grid-template-columns: repeat(14, 1fr);
        grid-template-rows: repeat(12, 1fr);
        grid-column: 1/15;
        grid-row: 1/10;
        justify-content: center;
    }
    .edit-header-btn, .edit-param{
        width: 85%;
    }
    .test-block{
        grid-column: 1/13;
    }
    .all-news{grid-row: 11/12;
    font-weight: 400;}
    .partner-content{
        grid-column: 2/14;
        grid-row: 1/10;
        display: grid;
        grid-template-columns: repeat(14, 1fr);   
        grid-template-rows: repeat(14, 1fr);
        column-gap:15px;
        row-gap: 10px;
    }
    .main-news{height: 100%;}
    .news-content{
        grid-template-rows: repeat(12,1fr);
        row-gap: 20px;
        column-gap: 10px;
    }
    .test-title{
        grid-row: 1/2;
        grid-column: 1/15;
    }
    .link{font-size: 1rem;}
    .main-logo{
        width:auto;
        height: 100%;
    }
    .main-text {
        grid-column: 3/11;
        font-size: 1.2rem;
    }
    .main-title{
        grid-column: 2/12;
    }
    .mercury img{
        width: 120px;
        height: auto;
    }
    .itst img{
        width: 120px;
        height: auto;
    }
    .mercury{
        grid-row:6/9;

    }
    #mercury,#itstars{
        display: none;
    }
    .itstars{
        grid-row:6/9;
    }
    .prize-content{
        grid-column: 2/14;
    }
    .ftizis-info{grid-column: 2/7;}
    .vector-info{grid-column: 9/14;}
}
@media screen and (max-height: 650px){
    .lang-img{
        margin-top: 0px;
    }
    .main-text{
        grid-row: 5/7;
    }
    .main-logo{
        grid-column: 5/9;
        grid-row: 1/4;
    }
    .main-title{
        text-align: center;
        grid-column: 5/9;
        grid-row: 4/5;
    }
    .imag{
        margin: 0;
    }
}
@media screen and (max-width: 600px){
    .link{
        font-size: 0.8rem;
    }
    .test-block-btn button{
        height: 70%;
        width: 80%;
    }
    .react-multi-carousel-list{
        width: 100%;
    }
    .main-title{
        grid-column: 2/12;
        grid-row: 4/5;
        font-size: 2rem;}
    .main-text{
        grid-column: 2/12;
        grid-row: 5/7;
        font-size: 0.9rem;}
    .test-block{
        grid-row: 7/9;
    }
}
@media screen and (max-width: 500px){
    .main-partner{
        padding-top: 20px;
        background-image: url('../images/reserv.png');
        height: 145%;
    }

    .main-contact{
        background-image: url('../images/cont.png');
        height: 120%;
        color: #FFFFFF;
    }
    .test-block{
        grid-row: 8/10;
    }
    .test-block-title{
        font-size: 1.5rem;
    }
    .version{
        grid-column: 1/13;
        font-size: 0.8rem;
        line-height: 1.3rem;
    }
    .test-block-btn button{
        font-size: 1.2rem;
        height: 75%;
        width: 90%;
    }
    .test-title-text{
        font-size: 1.5rem;
    }
    .title-text p{
        font-size: 1.5rem;
    }
    .link{padding: 0px 7px;}
    .partner-content{
        grid-row: 1/10;
        grid-column: 1/15;
        row-gap: 30px;
    }
    .partner-title{
        grid-row: 1/2;
    }
    .main-header-link{
        width: 100%;
        font-size: 10px;
    }
    .main-test{
        height: 120%;
    }
    .test-content{
        grid-template-columns: repeat(14, 1fr);   
        grid-template-rows: repeat(14, 1fr);
    }
    .main-logo{
        grid-column: 2/12;
        grid-row: 1/4;
        width:auto;
        height: 99%;
        display: flex;
        justify-content: center;
        align-items: flex-end
    }
    .main-title{
        grid-column: 2/12;
        grid-row: 4/5;
        font-size: 1.2rem;}
    .main-text{
        grid-column: 2/12;
        grid-row: 5/7;
        font-size: 0.9rem;}
    .mercury{
        grid-column: 7/13;
        grid-row: 7/11;
        justify-content: flex-end;
        display: flex;
        align-items: center;
    }
    .mercury img{
        width: 85px;
        height: auto;
    }
    .itst{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
    }
    .itst img{
        width: 85px;
        height: auto;
    }
    .itstars{
        grid-row: 8/10;
        grid-column: 1/6;
    }
    .imag{
        margin: 0;
        height: 80%;
    }
    .contact-content-title{
        grid-row: 1/5;
    }
    .social{
        grid-column: 6/10;
        grid-row: 11/12;
    }
    .contact-content{
        grid-row: 1/12;
        grid-template-rows: repeat(14,auto);
    }
    .ftizis-info{
        grid-column: 3/13;
        grid-row: 12/13;
    }
    .vector-info{
        grid-column: 3/13;
        grid-row: 13/14;
    }
}
@media screen and (max-width: 360px){
    .link{padding: 0px 2px;font-size: 0.7rem;}
    .auth-button{width: 100px;}
}
@media screen and (max-width: 400px){
    .partner-content{
        width: 95%;
    }
}


