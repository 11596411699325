body{
    min-height:700px
}
.authentificate{
    align-items: center;
    width: 100%;
    max-height: 100%;
    height: 100%;
    min-height:99%;
    background-image: url('../images/top-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    grid-template-columns: repeat(12,1fr);
}
.auth-form{
    grid-column: 4/10;
    height: 60%;
    min-height: 60%;
    max-height: 60%;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: repeat(12,1fr);
}
.auth-form-data{
    display: grid;
    grid-column: 1/7;
    height: 100%;
    background-color: #4FACDD;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(9,auto);
    align-items: center;
}
.auth-form-form .div-loading{
    height: 25%;
}
.rel-div{
    grid-column: 1/3;
    grid-row: 1/2;
    width: auto;
    height: auto;
    margin: 4px 0px 0px 25px;
}
.rel-div:hover{
    transform: scale(1.2);
    transition: 0.2s;
}
.rel-div:not(hover){
    transform: scale(1);
    transition: 0.3s;
}
.rel-div:active{
    transform: scale(0.97);
}
.auth-form-content{
    grid-column: 2/12;
    grid-row: 1/10;
    height: 60%;
}
.auth-logo-content img{
    height: auto;
    max-height: 100%;
    width: auto;
    max-width: 100%;
}
.auth-form-title{
    width: 100%;
    height: 15%;
    color: #FFFFFF;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.auth-form-form{
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.auth-form-input{
    width: 80%;
    height: 30%;
    display: flex;
    align-items: center;
}
.auth-form-input input{
    height: 60%;
    border-style: none;
    text-align: center;
    font-size: 1.2rem;
    border-radius: 5px;
    font-weight: 400;
    padding: 0px;
    outline:none;
}
.only-input{
    width: 100%;
    height: 80%;
    flex-direction: column;
    display: flex;justify-content: center;
    align-items: center;
}
.auth-form-button{
    width: 80%;
    height: 25%;
}
.auth-form-button button{
    height: 60%;
    border-style: none;
    border-radius: 5px;
    font-weight: 400;
    font-size: 1.2rem;
    background-color: #0085CE;
    color: #FFFFFF;
    padding: 0px
}




.auth-form-logo{
    grid-column: 7/13;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #FFFFFF;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    align-items: center
}

.auth-logo-content{
    grid-column: 2/12;
    height:60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.auth-logo{
    height: 70%;
    display: flex;
    widows: 100%;
    justify-content: center;
}
.auth-text{
    width: 90%;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    text-align: center;
    height:20%;
    color: #4FACDD;
}


@media screen and (max-width:1024px) {
    .auth-form{
        grid-column: 2/12;
    }
}
@media screen and (max-width:800px) {
    .auth-form-content{
        grid-column: 1/13;
    }
}
@media screen and (max-width:600px) {
    .auth-form{
        height: 90%;
        max-height: 90%;
        grid-template-rows: repeat(12,auto);
        box-shadow: none;
    }
    .auth-form-logo{
        grid-column: 2/12;
        grid-row: 1/3;
        background-color: inherit;
    }
    .rel-div{
        margin-top: 8px;
    }
    .auth-form-data{
        grid-column: 2/12;
        grid-row: 3/13;
        border-radius: 20px;
        max-height: 80%;
    }
    .auth-text{
        display: none;
    }
    .auth-logo-content{
        height: 100%;
    }
    .auth-form-content{
        height: 90%;
    }
}