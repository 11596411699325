.stat-main{
    display: grid;
    width: 80%;
    box-sizing: border-box;
    padding-right: 10px;
    height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    row-gap: 20px;
    column-gap: 20px;
    padding-top: 10px;
}
.stat-main-info{
    grid-column:1/13;
    border-radius: 10px;
    grid-row: 1/8;
    background-color: #4DAFE4;
    display: grid;
    grid-template-columns: repeat(14,1fr);
    grid-template-rows: repeat(12,1fr);
}
.stat-input-diagram{
    grid-column: 1/9;
    grid-row: 3/13;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.stat-input{
    grid-column: 1/15;
    grid-row: 1/3;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.stat-input-field{
    width: 17%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.stat-input-button{
    width: 17%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.stat-input-field input, #select,option{
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    border-style: none;
    border-radius: 10px;
    background-color: #9CDCFF;
}
.stat-input-button button{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    background-color: #0085CE;
    color: #FFFFFF;
    border-style: none;
    font-size: 1rem;
}
.stat-diagram{
    height: 72%;
    width: 92%;
    display: flex;
}
.research-table{
    background-color:#4DAFE4;
    grid-column: 1/13;
    grid-row: 8/13;
    z-index: 1;
    border-radius: 10px;
    height: 98%;
    overflow: auto;
    border: 1px solid #0085CE;
    box-sizing: border-box;
    position: relative;
}
.research-table button{
    color: #FFF;
}
.research-table .pagination-block{
    width: 60%;
    height: auto;
}
.research-table input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #fff;
}
.research-table-close{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.stat-info-text{
    grid-column: 9/14;
    grid-row: 3/11  ;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.detalization {
    background-color: #4dafe4;
    border: 1px solid #0085ce;
    border-radius: 10px;
    grid-column: 1/13;
    grid-row: 8/13;
}
.loading-div-block{
    grid-row: 1/15;
    grid-column: 9/14;
}
.loading-div-block .div-loading{
    height: 85%;
}
.stat-info-button{
    grid-column: 9/14;
    grid-row: 11/12;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.stat-info-button button{
    width: 90%;
    height: 80%;
    background-color: #0085CE;
    color: #FFFFFF;
    border-radius: 10px;
    border-style: none;
    font-size: 1rem ;
}
.research-table-data{
    width: 100%;
    align-items: center;
    color: #FFF;
    height: 95%;
}

.res-tbody tr:hover{
    background-color: #0085CE;
    cursor: pointer;
}
.res-tbody tr:not(hover){
    transform: scale(1);
    transition: 0.2s;
}
.res-tbody tr:active{
    transform: scale(0.98);
    transition: 0.1s;
}

@media screen and (max-width:1100px){
    .stat-main{
        width: 98%;
    }
}
.res-table{
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}
.rs-th,.rs-td{
    width: auto;
    height: 45px;
}

.rs-td{
    width: auto;
    height: 30px;
    max-height: 30px;
    text-align: center;
    margin-top: 5px;
    border: 2px solid #FFF;
}
.res-tbody tr{
    border-bottom: 2px solid #FFF;
}
.res-tbody{
    width: 100%;
    height: auto;
    max-height: 90%;
    overflow-y: auto;
}
#res-thead{
    height: 45px;
    position: fixed;
}

@media screen and (max-width:700px){
    .loading-div-block{
        grid-column: 1/15;
        grid-row: 5/10;
    }
    .div-loading{height: 100%;}
    .stat-main{
        height: 200%;
    }
    .stat-input{
        height: 100%;
        grid-row: 1/2;
        justify-content: space-around;
    }
    .stat-main-info{
        grid-row: 1/8;
    }
    .stat-input-diagram{
        grid-column: 1/15;
        grid-row: 2/6;
    }
    .stat-diagram{
        height: 100%;
        display: flex;
        align-items: flex-start;
    }
    .stat-info-text{
        grid-row: 6/12;
        grid-column: 2/14;
        height: 90%;
    }
    .stat-info-button{
        grid-row: 12/14;
        grid-column: 1/17;
    }
    .stat-info-button button{
        width: 75%;
        font-size: 0.9rem;
    }
    .stat-input-field input, #select{
        height: 30px;
    }
    #select{
        width: 80px;
    }
    .stat-input-button button{
        height: 30px;
        width: 100px;
        font-size: 0.8rem;;
    }
}
@media screen and (max-width:500px){
    .stat-main{
        height: 160%;
    }
}
@media screen and (max-width:400px){
    .stat-main-info{
        grid-row: 1/7;
    }
    .research-table{
        grid-row: 7/13;
    }
    .stat-info-text .info-txt{
        font-size: 0.8rem;
    }
}