.user-main{
    display: grid;
    width: 80%;
    height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(14,1fr);
    padding-right: 10px;
    box-sizing: border-box;
    row-gap: 20px;
    column-gap: 20px;
}
.individual-info{
    grid-column:1/6;
    border-radius: 10px;
    grid-row: 1/11;
    background-color: #4DAFE4;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#updatefield{
    max-width: 50%;
}
.personal-info{
    grid-column:1/7;
    border-radius: 10px;
    grid-row: 1/8;
    background-color: #4DAFE4;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.count-info{
    grid-column:7/13;
    border-radius: 10px;
    grid-row: 1/8;
    background-color: #4DAFE4;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.individual-count-info{
    grid-column:7/13;
    border-radius: 10px;
    grid-row: 1/8;
    background-color: #4DAFE4;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.individual-info .block-content{
    height: auto;
}
.individual-info .block-content .info-txt{
    padding: 15px 0px;
}
.individual-info .block-content button{
    height: auto;
    width: auto;
    border-radius: 10px;
    background-color: #0085CE;
    outline: none;
    padding: 10px 15px;
    border: 0;
    margin: 10px 0px;
    box-sizing: border-box;
    color: #fff;
    font-size: 15px;
}
.info-button{
    display: flex;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: center;
}
.count-info .block-content{
    height: 70%;
}
.count-download-button{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.count-download-button button{
    width: 60%;
    height: 60%;
    color: #fff;
    background-color: #0085CE;
    border-radius: 10px;
    border-style: none;
    font-size: 1rem;
}
.individ-finance-buttons{
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
}
.individ-finance-buttons button{
    width: auto;
    cursor: pointer;
    padding: 7px 15px;
    box-sizing: border-box;
    height: auto;
    color: #fff;
    align-items: center;
    background-color: #0085CE;
    border-radius: 10px;
    border-style: none;
    margin-bottom: 10px;
    font-size: 1rem;
}
.stat-info{
    grid-column:1/13;
    border-radius: 10px;
    grid-row: 8/15;
    background-color: #4DAFE4;
}
.block-name{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.tarif-block-name{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.div-loading{
    width: 100%;
    height: 70%;
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.block-name-text{
    width: auto;
    padding: 5px 10px;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    background-color: #0085CE;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
}
.block-content{
    height: 75%;
    width: 93%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}
.info-txt{
    color: #fff;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 3px solid #fff;
}
.stat-info-content{
    width: 100%;
    height: 85%;
    display: grid;
    grid-template-columns: repeat(20,1fr);
    grid-template-rows: repeat(10,1fr);
    column-gap: 10px;
}
.stat-info-diagram{
    grid-column: 1/11 ;
    padding-left: 20px;
    width: 92%;
}
.stat-info-data{
    grid-column: 12/20;
    display: flex;
    height: 80%;
    flex-direction: column;
    justify-content: space-around;
}
.tarif-name-text{
    width: auto;
    padding: 5px 10px;
    height: 30%;
    display: flex;
    justify-content: center;
    margin: 13px 0px;
    align-items: center;
    background-color: #0085CE;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
}
.tarif-info{
    grid-column: 7/13;
    border-radius: 10px;
    grid-row: 1/6;
    background-color: #4DAFE4;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tarif-table{
    width: 95%;
    height: auto;
    table-layout: fixed;
}
.tarif-table-data{
    table-layout: fixed;
    color: #fff;
    border: 2px solid #fff;
    border-collapse:collapse
}
.tarif-table-th{
    width: auto;
    border: 2px solid #fff;
    padding: 10px 0px;
}
.tarif-table-td{
    width: auto;
    border: 2px solid #fff;
    padding: 10px 10px;
}




@media screen and (max-width:1100px){
    .user-main{
        width: 98%;
    }
}

@media screen and (max-width:650px){
    .personal-info{
        grid-row:1/5;
        grid-column: 1/13;
    }
    .count-info{
        grid-row: 5/9;
        grid-column: 1/13;
    }
    .stat-info{
        grid-column: 1/13;
        grid-row: 9/15;
    }
    .user-main{
        height: 160%;
    }
    .stat-info-diagram{
        grid-column: 1/21;
        grid-row: 1/5 span;
        padding-left: 13px;
    }
    .stat-info-data{
        grid-column: 2/20;
    }
    .count-download-button button{
        width: 85%;
    }
    .stat-info-data{
        grid-row: 6/11;
        height: 90%;
    }
    .personal-info .info-txt{
        font-size: 0.85rem;
    }
    .count-info .info-txt{
        font-size: 0.85rem;
    }
    .stat-info-data .info-txt{
        font-size: 0.85rem;
    }
}