tbody::-webkit-scrollbar {
    width: 10px;
}
tbody::-webkit-scrollbar-thumb {
    background-color: #A3DDF3;    /* цвет плашки */
    border-radius: 20px;       /* закругления плашки */
}

.uploaded-image button{
    width: auto;
    height: auto;
    border-radius: 10px;
    background-color: #0085CE;
    outline: none;
    border: none;
    padding: 12px 15px;
    color: #FFF;
    font-size: 15px;
}
.user-research{
    display: grid;
    width: 80%;
    padding-right: 10px;
    box-sizing: border-box;
    height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    row-gap: 10px;
    column-gap: 10px;
}
.input-file{
   grid-column: 3/11;
   grid-row: 1/4;
   background-color:#4DAFE4;
   border-radius: 10px;
   display: flex;
   flex-direction: column   ;
   justify-content: center;
   align-items: center;
}
#file{
    display: none;
}

.edit-div{
    position: absolute;
    top: 0;
    left: 0;
}

.input-file-title, .input-file-field, .input-file-btn{
    height: 33%;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: #FFF;
    font-size: 1.3rem;
}
.input-file-title-text, .input-file-field label, .input-file-btn button{
    width: auto;
    height: 50%;
    display: flex;
    align-items: center;
    border-style: none;
    border-radius: 10px;
    text-align: center;
    padding: 0px 20px;
}
.input-file-field label, .input-file-btn button{
    background-color: #0085CE;
    color: #FFF;
    font-size: 1.3rem;
}
.input-file-field label:active{
    transform: scale(0.97);
    transition: 0.2s;
}


.output-file{
    grid-column: 1/13;
    grid-row:1/13;
    height: 100%;
    width: auto;
    background-color: #4DAFE4;
    border-radius: 10px;
    border: 2px solid #4DAFE4;
    display: flex;
    box-sizing: border-box;
}
.output-file-info{
    height: auto;
    width: 30%;
    padding: 3.5% 2% 0% 2%;
    border-radius: 10px 0px 0px 10px;
    background-color: #A2DFFF;
    box-sizing: border-box;
}
.output-file-info .div-loading{
    width: auto;
    height: auto;
    min-height: auto;
}
.output-info-field{
    width: 100%;
    height: auto;
    border-radius: 10px 0px 0px 10px;
}
.edit-div label{
    border: none;
}
.output-file-description{
    width: auto;
    height: auto;
    text-align: justify;
    line-height: 25px;
}
.software-version{
    line-height: 35px;
}
.software-version-radio{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
}
.software-version-radio label{
    width: auto;
    height: auto;
    padding: 0px 10px;
    border: 2px solid #FFF;
    margin: 0px 10px;
}
.output-file-button{
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0px;
    border-bottom: 3px solid #FFF;
}
.output-file-button button{
    width: auto;
    height: auto;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #0085CE;
    outline: none;
    border: 1px solid #0085CE;
    color: #FFF;
    font-size: 15px;
}
.output-file-field{
    width: 70%;
    max-height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 30px 30px 30px;
    flex-direction: column;
    box-sizing: border-box;
    background-color: transparent;
}
.file-description{
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.output-conclusion-prob{
    width: 100%;
    padding-top: 20px;
    height: auto;
    display: flex;
    padding-bottom: 15px;
    line-height: 25px;
}
.output-conclusion-prob p{
    width: auto;
    height: auto;
}
.output-conclusion-prob button{
    width: auto;
    height: 45px;
    background-color: #0085CE;
    color: #FFF;
    border-radius: 10px;
    border: 0;
    font-size: 15px;
    padding: 10px 15px;
    white-space: nowrap;
}
.output-conclusion{
    padding: 5px 0px;
}
.filing{
    width: 100%;
    height: auto;
    max-height: 95%;
    min-height: 95%;
    border: 2px dashed #FFF;
    border-radius: 10px;
}
.output-file-title{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    padding: 15px;
    color: #FFF;
    font-size: 20px;
    text-align: center;
}
.uploaded-image-button{
    height: auto;
}
.output-file-field img{
    width: auto;
    height: auto;
    max-height: 93%;
    max-width: 100%;
    background-color: transparent;
}
.uploaded-image{
    width: auto;
    height: auto;
    max-height:100%;
    box-sizing: border-box;
    background-color: transparent;
}
#imagination{
    width: auto;
    height: auto;
    min-height:90%;
    max-width: 100%;
}
.output-file-field label{
    width: auto;
    box-sizing: border-box;
    height: auto;
    display: flex;
    border:  none;
    font-size: 30px;
    flex-direction: column;
    max-height: 100%;
    min-height: 100%;
    justify-content: center;
    background-color: #A2DFFF;
}
.onDragDiv{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: antiquewhite;
}
@media screen and (max-width:1100px){
    .user-research{
        width: 98%;
    }
    .research-table-data table{
        overflow: scroll;
    }
    .input-file{
        grid-column: 4/10;
    }
    .output-file{
        grid-column: 3/11;
    }
}
@media screen and (max-width:800px){
    .output-file{
        grid-column: 1/13;
    }
    .input-file{
        grid-column: 3/11;
    }
}
@media screen and (max-width:550px) {
    .output-file-field img{
        width: 300px;
        height: auto;
        max-height: 350px;
        max-width: 98%;
    }
}
