.admin-finance {
    display: grid;
    width: 80%;
    box-sizing: border-box;
    padding-right: 10px;
    min-height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    row-gap: 20px;
    column-gap: 20px;
    height: 130%;
    padding-bottom: 10px;
}
.admin-finance-table {
    grid-column: 1/13;
    grid-row: 1/6;
    background-color: #4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
}
.admin-finance-table-title {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0px ;
}
.admin-finance-table-form{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px ;
}
.finance-button{
    width: auto;
    height: auto;
    outline: none;
    padding: 8px 13px;
    margin: 0px 10px;
    border-radius: 10px;
    border: none;
    background-color: #0085CE;
    color: #FFF;
    font-size: 20px;
}
.finance-input{
    width: auto;
    height: auto;
    outline: none;
    padding: 10px 13px;
    margin: 0px 10px;
    font-size: 15px;
    border-radius: 10px;
    border: none;
    background-color: #9cdcff;
}
.admin-finance-table-data {
    width: 98%;
    height:65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}
.admin-stat-table-title-text{
    font-size: 25px;
    padding: 7px 17px;
}
.admin-finance-table-1 {
    grid-column: 1/13;
    grid-row: 9/13;
    background-color: #4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
}
.admin-finance-table-2{
    grid-column: 1/13;
    grid-row: 6/9;
    background-color: #4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
}
.admin-finance-table-note{
    width:100%;
    height: auto;
}
.admin-stat-table-note-text{
    padding: 5px 20px;
    box-sizing: border-box;
}
#finance-table-div{
    overflow: visible;
    height: 100%;
    max-height: 100%;
}
#finance-table{
    height: 100%;
    max-height: 100%;
    overflow-y: hidden;
}
#finance-thead>th{
    height: 5%;
    max-height: 5%;
    border: 2px solid #FFFFFF;
}
#finance-tbody{
    height: 95%;
    max-height: 95%;
    overflow: scroll;
}
#finance-table-div {
    position: relative;
    max-height: 100%; /* Установите максимальную высоту контейнера таблицы */
    overflow-y: auto;
}

#finance-table {
    width: 100%;
}

#finance-thead {
    position: sticky;
    top: 0;
    background-color: #4DAFE4;
    z-index: 1; /* Убедитесь, что заголовок находится над строками таблицы */
}
#finance-tbody>.stat-tr-th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4CAF50;
    color: white;
}

#finance-tbody {
    overflow-y: auto;
}
#ad-stat-tbody>td{
    box-sizing: border-box;
}