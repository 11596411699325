.admin-organizations{
    display: grid;
    width: 80%;
    box-sizing: border-box;
    padding-right: 10px;
    height: 99%;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    row-gap: 20px;
    column-gap: 10px;
}
.admin-organizations-table{
    grid-column: 1/13;
    grid-row: 1/7;
    background-color: #4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.admin-organizations-table-data{
    width: 99%;
    height: 98%;
    overflow: auto;
    display: flex;
    justify-content: center ;
}
.admin-organization-table{
    width: 99%;
    height: auto;
}
.admin-organizations .ad-user-edit{
    height: auto;
    width: 100%;
    padding: 10px 10px;
}