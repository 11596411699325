.admin-users{
    display: grid;
    width: 80%;
    box-sizing: border-box;
    padding-right: 10px;
    height: 99%;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    row-gap: 20px;
    column-gap: 10px;
}
.admin-users-table{
    grid-column: 1/13;
    grid-row: 1/7;
    background-color: #4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.admin-users-table-data{
    width: 97%;
    height: 98%;
}
.admin-user-table{
    width: 100%;
    height: auto;
}
.ad-user-tr{
    width: 100%;
    height: auto;
}
.ad-user-th{
    height: 60px;
    width: auto;
    color: #FFF;
    font-size: 1.2rem;
}
.ad-user-td{
    height: 35px;
    width: auto;
    color: #FFF;
    text-align: center;
    font-size: 1.2rem;
    border: 2px solid #FFF;
}
.dialog-div{
    position: fixed;
    grid-column: 1/12;
    grid-row:1/12;
}
.ad-user-td-btn{
    height: 35px;
    width: auto;
    color: #FFF;
    text-align: center;
}
.ad-user-edit{
    width:80%;
    border-radius: 10px;
    background-color:#0576B6;
    border-style: none;
    outline: none;
    color: #FFF;
    font-size: 1rem;
}
.ad-user-delete{
    width: 80%;
    border-radius: 10px;
    background-color:#C60000;
    border-style: none;
    color: #FFF;
    outline: none;
    font-size: 1rem;
}
.admin-users-table{
    overflow: auto;
}

.admin-users-form{
    grid-column: 4/10;
    width: 80%;
    grid-row: 7/13;
    background-color: #4DAFE4;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}
.admin-users-form-title{
    width: 95%;
    height: 15%;
    display: flex;
    font-size: 1.5rem;
    color: #FFF;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.admin-users-form-input-text-block{
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    margin-left: 0px;
    font-size: 1.3rem;
}
.admin-users-form-input{
    width: 95%;
    height: 70%;
    display: flex;
    justify-content: center;
}
.admin-users-form-input-text{
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #FFF;
}
.admin-users-form-input-field{
    width: 52%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.admin-users-form-input-text-block input,.admin-users-form-input-text-block select{
    width: 100%;
    height: 70%;
    border-radius: 10px;
    background-color: #A3DDF3;
    border-style: none;
    padding: 0px 0px 0px 10px;
    color: rgb(56, 56, 56);
}

.admin-users-form-button{
    width: 95%;
    height: 15%;
    display: flex;
    justify-content:space-evenly;
    align-items: center;
}
.admin-users-form-button button{
    width: auto;
    padding: 0px 10px;
    height: 70%;
    background-color: #0085CE;
    border-radius: 10px;
    border-style: none;
    outline: none;
    color: #FFF;
    font-size: 1.2rem;
}
@media screen and (max-width:1100px){
    .admin-users{
        width: 98%;
    }   
}
@media screen and (max-width:750px) {
    .admin-users-table-data thead tr,.admin-users-table-data tbody tr{
        justify-content: flex-start;
    }   
    .admin-users-form{
        grid-column: 3/11;
    }
    .admin-users-form-title{font-size: 1.5rem;}
}
@media screen and (max-width:500px){
    .admin-users-form{
        grid-column: 1/13;
        width: 100%;
    }
    .ad-user-edit{
        width: 95%;
    }
    .ad-user-delete{
        width: 95%;
    }
    .admin-users-form-title{font-size: 1.1rem;}
    .ad-user-td,.ad-user-th{
        font-size: 1rem;
    }
    .admin-users-form-input-text-block{
        font-size: 1rem;
    }
    .admin-users-form-button button{
        font-size: 1rem;
    }
}