.news-form{
    border-radius: 10px;
    background-color: #A3DDF3;
    box-shadow: 0px 0px 20px 2px rgb(46, 46, 46);
}
.news-form a{
    text-decoration: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.news-img{
    height: 50%;
}
.news-text{
    height: 45%;
    text-align: center;
    color: #333333; 
}
.news-form img{
    width: 100%;
    background-repeat: no-repeat;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
}
.news-form:hover{
    transform: scale(1.05);
    transition: 0.2s;
}
.news-form:not(hover){
    transform: scale(1);
    transition: 0.2s;
}
.news-form:active{
    transform: scale(0.97);
}

#news-1{
    grid-column: 2/5;
    grid-row: 3/9;
}
#news-2{
    grid-column: 5/8;
    grid-row: 3/9;
}
#news-3{
    grid-column: 8/11;
    grid-row: 3/9;
}
#news-4{
    grid-column: 11/14;
    grid-row: 3/9;
}

.news-card img{
    width: 100%;
    height: auto;
    max-height: 100%;
    background-repeat: no-repeat;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
}
.news-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
    text-decoration: none;
    color: #333333;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 20px 20px 100px -60px #5a5a5a;
}
.news-card-btn{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.news-card-btn-date{
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
}
.news-card-btn-type{
    width: auto;
    height: auto;
    display: flex;
    border-radius: 20px;
    border:1px solid black;
    padding: 10px 16px;
    align-items: center;
}
.news-card-btn-block{
    width: 80%;
    height:auto;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
}
.news-card-btn a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

#newsid:hover{
    transform: scale(1.05);
    transition: 0.4s;
}
#newsid:not(hover){
    transform: scale(1);
    transition: 0.4s;
}

.news-card-btn button{
    width: 65%;
    height: 40%;
    background-color: #1AB0E9;
    border-style: none;
    border-radius: 5px;
    font-size: 1rem;
    color: #ffffff;
}
.news-card-text{
    height: 30%;
    display: flex;
    width: 85%;
    padding-top: 20px;
    justify-content: center;
}
.news-card-img{
    max-height: 55%;
    width: 100%;
}

@media screen and (max-width:800px) {
    .news-text{
        font-size: 0.8rem;
    }
    .news-img{
        height: 50%;
    }
    .news-text{
        height: 45%;
    }
    .news-img img{
        height: 100%;
    }
    #news-1{
        grid-column: 3/8;
        grid-row: 3/7;
    }
    #news-2{
        grid-column: 8/13;
        grid-row: 3/7;
    }
    #news-3{
        grid-column: 3/8;
        grid-row: 7/11;
    }
    #news-4{
        grid-column: 8/13;
        grid-row: 7/11;
    }     
}

@media screen and (max-width:500px) {
    #news-1{
        grid-column: 2/8;
        grid-row: 3/7;
    }
    #news-2{
        grid-column: 8/14;
        grid-row: 3/7;
    }
    #news-3{
        grid-column: 2/8;
        grid-row: 7/11;
    }
    #news-4{
        grid-column: 8/14;
        grid-row: 7/11;
    }
}